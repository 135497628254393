._2NIQygpbT_ZyP6p5EDuKRA {
  --translation: 0;
  --thickness: 1px;
  --width: 13px;

  position: absolute;
  background-color: #ccc;
}

.DQG0SKgBwMmDeSMoKMyAv {
  top: 0;
  left: 0;
  -webkit-transform: translateX(calc(var(--translation) - 50%));
          transform: translateX(calc(var(--translation) - 50%));
  width: var(--thickness);
  height: var(--width);
}

._2U56kg4_SKhEQAHtxvbYH5 {
  top: 0;
  right: 0;
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
  width: var(--width);
  height: var(--thickness);
}

._1_zrfuYrs40-ZgdTTacrhN {
  --translation: 0;
  --separation-from-axis: 20px;

  position: absolute;
}

._2dot0ELynh24Pnlu7rF0an {
  top: calc(var(--separation-from-axis) + 0.5rem);
  left: 0;
  -webkit-transform: translateX(calc(var(--translation) - 50%)) rotate(-65deg);
          transform: translateX(calc(var(--translation) - 50%)) rotate(-65deg);
}

._1mt7FwioQdXnKV9Z9ksHvk {
  top: 0;
  right: var(--separation-from-axis);
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
}

._3NPQo8PuKsI651R6fkhmL- {
  --thickness: 1px;
  --translation: 0;

  position: absolute;
  background-color: #ccc;
}

._1RRgZ4imM3LvWYarAGTziy {
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--thickness);
  -webkit-transform: translateX(calc(var(--translation) - 50%));
          transform: translateX(calc(var(--translation) - 50%));
}

._3O4Ed3lJB7_lfAn2mUE1dO {
  top: 0;
  left: 0;
  right: 0;
  height: var(--thickness);
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
}
