._3sb-_VQOauFG8cP9rjgBbS {
  --translationX: 0;
  --translationY: 0;
  --rotation: 0;
  --width: 0;
  --height: 0;

  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  top: 0;
  left: 0;
  color: white;
  width: var(--width);
  height: var(--height);
  opacity: 0.7;
  font-weight: 300;
  -webkit-transform:
    translateX(calc(var(--translationX) - 50%))
    translateY(calc(var(--translationY) - 50%))
    rotate(var(--rotation));
          transform:
    translateX(calc(var(--translationX) - 50%))
    translateY(calc(var(--translationY) - 50%))
    rotate(var(--rotation));
}
