._1rZhLleidxKQLjs1szZ_7P {
  --thickness: 1px;
  --scale-factor: 1;
  --stroke-color: rgba(0, 0, 0, .25);

  position: absolute;
}

._2hgCrWMpOqtUX24oiSW8m7 {
  top: var(--distance-from-top);
  left: 0;
  bottom: 0;
  width: var(--thickness);
  background-color: var(--stroke-color);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  -webkit-transform: translateX(calc(var(--translation) - 50%));
          transform: translateX(calc(var(--translation) - 50%));
}

._37wKHQnbiSjYnQskk7PpH9 {
  height: 0;
  border-top: 2px var(--stroke-color) dashed;
  top: 0;
  left: 0;
  right: var(--distance-from-right);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
}
