._3dUqiPgLvU3xK6m9DZYjvZ {
  --thickness: 3px;
  --width: 12px;
  --translation: 0;
  --color: white;

  position: absolute;
  background-color: var(--color);
}

._2TqiNEmzbOzvq6gs9dDtIv {
  top: 0;
  left: 0;
  -webkit-transform: translateX(calc(var(--translation) - 50%));
          transform: translateX(calc(var(--translation) - 50%));
  width: var(--thickness);
  height: var(--width);
}

._2CPe73oWjHv4Ka11MOMdMs {
  top: 0;
  right: 0;
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
  width: var(--width);
  height: var(--thickness);
}

.S6EgS5JfBENAk1U-P9XYT {
  --label-separation-from-axis: 20px;
  --translation: 0;
  --color: white;

  border: 4px solid var(--color);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
  background-color: white;
}

._3eYB_N24EtKVj95NzQdUcR {
  top: var(--label-separation-from-axis);
  left: 0;
  -webkit-transform: translateX(calc(var(--translation) - 50%));
          transform: translateX(calc(var(--translation) - 50%));
}

._1OZZpx_XR7QJl7sFpL_Jik {
  top: 0;
  right: var(--label-separation-from-axis);
  -webkit-transform: translateY(calc(var(--translation) - 50%));
          transform: translateY(calc(var(--translation) - 50%));
}
